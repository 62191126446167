<div class="container-sm col-6 py-4 formulario-container">
  <div class="row justify-content-center align-items-center">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title text-center">Iniciar sesión</h5>
        </div>
        <div class="card-body">
          <form (ngSubmit)="onLogin()" name="loginForm" [formGroup]="loginForm" class="text-center">
            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label">Número de Afiliación</label>
                <input type="text" class="form-control" formControlName="idAfiliacion" id="idAfiliacion" placeholder="Ingresa tu número de afiliación">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <label class="form-label">Password</label>
                <input type="password" class="form-control" formControlName="password" id="password" placeholder="Ingresa tu password">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <button type="submit" class="btn btn-primary">Acceder</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
