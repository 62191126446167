import { AfiliadosCategoriasDto } from "../Core/Model/AfiliadosCategoriasDto";

export const CATEGORIAS: AfiliadosCategoriasDto[] = [
    {
      id: 1,
      descripcion: "Amateur"
    },
    {
      id: 2,
      descripcion: "Profesional"
    },
    {
      id: 3,
      descripcion: "Nacional"
    },
    {
      id: 4,
      descripcion: "Internacional"
    }
  ];
  