
<div class="justify-content-center">
    <div class="container py-4 formulario-container">
        <div class="container-md">
            <div class="text-center">
                <h1 class="titulo-principal">Junta Directiva</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Presidente</h3>
                        <div class="mb-1 text-body-secondary">Ildefonso Urizar de Azpitarte</div>
                        <p class="card-text mb-auto">Correo: urizar&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/ilbon urizar-P.jpg"
                            class="bd-placeholder-img fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/ilbon urizar-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="300" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h2 class="mb-2">Trayectoria</h2>
                        <div class="mb-1 text-body-secondary">Ex-Arbitro internacional de futbol</div>
                        <div class="mb-1 text-body-secondary">Vicepresidente del Comite Técnico de Arbitros de la R.F.E.F</div>
                        <div class="mb-1 text-body-secondary">Ex-Presidente de la A.N.A.F.E-A.D.A.D</div>
                        <div class="mb-1 text-body-secondary">Ex-Presidente Comite Vasco de Arbitros</div>
                        <div class="mb-1 text-body-secondary">Empresario</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vicepresidente</h3>
                        <div class="mb-1 text-body-secondary">Jose Maria Garcia-Aranda</div>
                        <p class="card-text mb-auto">Correo: garcia-aranda&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/Garcia-Aranda-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/Garcia-Aranda-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h2 class="mb-2">Trayectoria</h2>
                        <div class="mb-1 text-body-secondary">Ex-Arbitro internacional de futbol</div>
                        <div class="mb-1 text-body-secondary">FIFA- Director del departamento Arbitraje 2003-2010.</div>
                        <div class="mb-1 text-body-secondary">UEFA Ex-Miembro Comision de Arbitrajes.</div>
                        <div class="mb-1 text-body-secondary">Licencia en Educacion fisica.</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vicepresidente Segundo </h3>
                        <div class="mb-1 text-body-secondary">Antonio Pérez</div>
                        <p class="card-text mb-auto">Correo: aperez&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/antonio perez-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/antonio perez-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h2 class="mb-2">Trayectoria</h2>
                        <div class="mb-1 text-body-secondary">Actualmente Presidente Comite Nacional de Jueces de Atletismo.</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vicepresidente Tercero</h3>
                        <div class="mb-1 text-body-secondary">Rodrigo Costas</div>
                        <p class="card-text mb-auto">Correo: costas&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/rodrigo-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/rodrigo-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h2 class="mb-2">Trayectoria</h2>
                        <div class="mb-1 text-body-secondary">Ex arbitro Internacional de balonmano.</div>
                        <div class="mb-1 text-body-secondary">Arbitro finales de Mundiales y Olimpiadas.</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vocal-Secretario</h3>
                        <div class="mb-1 text-body-secondary">Peru Ortiz de Mendibil</div>
                        <p class="card-text mb-auto">Correo: ortizdemendibil&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/peru-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/peru-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Ex-Arbitro Internacional de Hockey sobre hielo. </div>
                        <div class="mb-1 text-body-secondary">Formador.</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Secretario</h3>
                        <div class="mb-1 text-body-secondary">Jose Ignacio Gomara Diez</div>
                        <p class="card-text mb-auto">Correo: gomara&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/jose ignacio-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/jose ignacio-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Ex-Directivo Comite Vasco de Arbitros</div>
                        <div class="mb-1 text-body-secondary">Abogado</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vocal</h3>
                        <div class="mb-1 text-body-secondary">Jose Luis González González</div>
                        <p class="card-text mb-auto">Correo: jlgonzales&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/gonzales gonzales -P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/gonzales gonzales -T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Ex-Arbitro internacional de futbol</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vocal</h3>
                        <div class="mb-1 text-body-secondary"> Blanca Ibáñez Sedano</div>
                        <p class="card-text mb-auto">Correo: ibanez&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/blanca ibañes-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/blanca ibañes-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Ex-Arbitra de futbol</div>
                        <div class="mb-1 text-body-secondary">Ex-Directiva Comite de Arbitros</div>
                        <div class="mb-1 text-body-secondary">Azafata</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vocal</h3>
                        <div class="mb-1 text-body-secondary">Modesto Vázquez Riveiro</div>
                        <p class="card-text mb-auto">Correo: modesto&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/modesto-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/modesto-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Ex-Arbitro Asistente Internacional de Futbol</div>
                        <div class="mb-1 text-body-secondary">Ex-Director de Banca</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vocal</h3>
                        <div class="mb-1 text-body-secondary"> José María Benavente Garasa</div>
                        <p class="card-text mb-auto">Correo: benavente&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/benavente gara-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/BENAVENTE GARA-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Ex-Arbitro de primera division.</div>
                        <div class="mb-1 text-body-secondary">Ex-Vicepresidente comite tecnico de arbitros Pais Vasco</div>
                        <div class="mb-1 text-body-secondary">Empresario</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vocal</h3>
                        <div class="mb-1 text-body-secondary">Cristian Garcia Nieva</div>
                        <p class="card-text mb-auto">Correo: garcianieva&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/Cristian-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/Cristian-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Ex-Arbitro de futbol.</div>
                        <div class="mb-1 text-body-secondary">Presidente Comite Tecnico Arbitros de Castilla la Mancha
                        </div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vocal</h3>
                        <div class="mb-1 text-body-secondary">Andrey Jelev Demirev</div>
                        <p class="card-text mb-auto">Correo: andrey&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/andrey-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/andrey-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Ex-Arbitro Internacional de Waterpolo.</div>
                        <div class="mb-1 text-body-secondary">Delegado federativo de la federacion española de natación.
                        </div>
                        <div class="mb-1 text-body-secondary">Periodista.
                        </div>
                        
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vocal</h3>
                        <div class="mb-1 text-body-secondary">Antonio Correa Benítez</div>
                        <p class="card-text mb-auto">Correo: correa&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/Antonio Fernando-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/Antonio Fernando-T.png"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Ex-Arbitro Internacional de Volleyball.
                        </div>
                        <div class="mb-1 text-body-secondary">Empresario</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-4 ">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Vocal</h3>
                        <div class="mb-1 text-body-secondary">Angel Fernandez</div>
                        <p class="card-text mb-auto">Correo: angelfv&#64;anjade.es</p>
                    </div>
                    <div class="col-auto d-flex justify-content-center align-items-center">
                        <img src="assets/imagen/junta-directiva/angel-P.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div
                    class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div class="col-auto d-none d-lg-block">
                        <img src="assets/imagen/junta-directiva/angel-T.jpg"
                            class="bd-placeholder-img card-img-top fixed-size-image" width="100%" height="225" alt="Imagen de muestra">
                    </div>
                    <div class="col p-4 d-flex flex-column position-static">
                        <h3 class="mb-2">Trayectoria</h3>
                        <div class="mb-1 text-body-secondary">Cinturon negro de Taekwondo</div>
                        <div class="mb-1 text-body-secondary">Arbitro Internacional por la Federación Españolas de Artes Marciales Coreanas.</div>
                        <div class="mb-1 text-body-secondary">Abogado</div>
                        <p class="card-text mb-auto">
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
