<div class="justify-content-center">
  <div class="container py-4 formulario-container">
    <button type="submit" (click)="pay()" class="btn btn-primary">Pagar</button>

<button type="submit" (click)="endpoint()" class="btn btn-primary">endpoint</button>
  </div>
</div>




