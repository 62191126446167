import { Component } from '@angular/core';

@Component({
  selector: 'app-junta-directiva',
  templateUrl: './junta-directiva.component.html',
  styleUrls: ['./junta-directiva.component.css']
})
export class JuntaDirectivaComponent {

}
