<div class="container py-4 formulario-container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-header">
                    <h5 class="card-title text-center">Crear Nueva Noticia</h5>
                </div>
                <div class="card-body">
                    <div *ngIf="mensajeExito" class="alert alert-success" role="alert">
                        {{ mensajeExito }}
                    </div>

                    <!-- Barra de carga -->
                    <div *ngIf="cargando" class="progress mb-3">
                        <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%">
                            Cargando...
                        </div>
                    </div>
                    <form [formGroup]="noticiaForm" (ngSubmit)="onSubmit()">
                        <div class="mb-3">
                            <label for="titulo" class="form-label">Título</label>
                            <input id="titulo" formControlName="titulo" type="text" class="form-control">
                        </div>
                        <div class="mb-3">
                            <label for="linkOriginal" class="form-label">Link Original</label>
                            <input id="linkOriginal" formControlName="linkOriginal" type="text" class="form-control">
                        </div>
                        <div class="mb-3">
                            <label for="tipo" class="form-label">Tipo de Noticia</label>
                            <select id="tipo" formControlName="tipo" class="form-select">
                                <option value="PARTICULAR">Anjade</option>
                                <option value="GENERAL">Deportivas</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="imagenesLinks" class="form-label">Links de Imágenes (separados por comas)</label>
                            <textarea id="imagenesLinks" formControlName="imagenesLinks" class="form-control" rows="3"></textarea>
                        </div>
                        <button type="submit" class="btn btn-primary" [disabled]="!noticiaForm.valid">Crear Noticia</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>