<div class="container mt-4 pt-5 tabla-container">
  <h5 class="card-title filtro-card text-end">{{userlogin.nombre}} {{userlogin.apellidos}} - {{userlogin.usuariorol.descripcion}}</h5>
  <div class="card filtro-card">
    <div class="card-body">
      <div class="mb-3">
        <label for="filtro" class="form-label">Filtro</label>
        <input type="text" class="form-control" id="filtro" (keyup)="filtrar($event)"
          placeholder="Teclee el valor de la columna por la que desee filtrar" required />
        <div class="invalid-feedback">Se requiere teléfono numérico</div>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">
                <input
                type="checkbox"
                (change)="toggleSelectAll($event.target.checked)"
                [checked]="allRowsSelected()"
              />
              </th>
              <th scope="col">No. Afilición</th>
              <th scope="col">Apellidos</th>
              <th scope="col">Nombre</th>
              <th scope="col">Función</th>
              <th scope="col">Categoría</th>
              <th scope="col">Situación Actual</th>
              <th scope="col">Deportes</th>
              <th scope="col">Provincia</th>
              <th scope="col">Estado cuenta</th>
              <th scope="col">Rol de Afiliado</th>
              <th scope ="col">Detalles</th>
              <th scope="col">Editar</th>
              
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of getPaginatedData()" [ngClass]="getEstadoCuentaClass(element.estadoCuenta.id)">
              <td>
                <input type="checkbox" [(ngModel)]="element.selected" />
              </td>
              <td>{{element.idAfiliacion}}</td>
              <td>{{element.apellidos}}</td>
              <td>{{element.nombre}}</td>
              <td>{{element.afiliadosFuncion.descripcion}}</td>
              <td>{{element.afiliadosCategoria.descripcion}}</td>
              <td>{{element.situacionActual}}</td>

              <td>{{element.deporte.nombre}}</td>
              <td>{{element.provincia.descripcion}}</td>
              <td>{{element.estadoCuenta.estado}}</td>
              <td>{{element.usuariorol.descripcion}}</td>
              <td>
                <button (click)="viewUserDetails(element.id_user)" class="btn btn-link">
                  <i class="fas fa-eye"></i>
                </button>
              </td>
              <td>
                <button class="btn btn-outline-primary btn-sm" (click)="editarFila(element)">
                  <i class="bi bi-pencil"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav aria-label="Page navigation example">
        <mat-paginator [length]="dataSource.filteredData.length" [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

      </nav>
    </div>
  </div>

  <div class="mt-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <div class="me-2" id="paymentReminderDiv">
          <div class="bg-danger text-white p-2" (click)="enviarRecordatorioPago()">Pendiente de pago</div>
        </div>
        <div class="me-2">
          <div class="bg-warning text-dark p-2">Pendiente de revisión</div>
        </div>
      </div>
      <div>
        <button class="btn btn-primary me-2" (click)="openEmailModal()">
          <i class="fas fa-envelope me-2"></i> Comunicación Email
        </button>
        <button class="btn btn-primary" (click)="downloadExcel()">
          <i class="fas fa-file-excel me-2"></i> Descargar Lista
        </button>
        <!-- Aquí puedes agregar más botones en el futuro -->
      </div>
    </div>
  </div>
</div>

