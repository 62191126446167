<div class="modal-header">
  <h4 class="modal-title">Editar perfil: {{ data.nombre }} {{ data.apellidos }} </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form (ngSubmit)="onRegistro()" [formGroup]="registroForm" class="modal-body">
    <div class="form-group">
      
    </div>
    <div class="form-group">
      <label for="usuariorol">Roles</label>
      <select formControlName="usuariorol" class="form-control" id="usuariorol">
        <option *ngFor="let option of usuariosRoles" [value]="option.id">{{ option.descripcion }}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="estadoCuenta">Estado Afiliación</label>
      <select formControlName="estadoCuenta" class="form-control" id="estadoCuenta">
        <option *ngFor="let option of estadosUsuariosList" [value]="option.id">{{ option.estado }}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="showPasswordFields">
      <label for="password">Contraseña</label>
      <input formControlName="password" type="password" class="form-control" id="password">
    </div>
    <div class="form-group" *ngIf="showPasswordFields">
      <label for="confirmPassword">Confirmar Contraseña</label>
      <input formControlName="confirmPassword" type="password" class="form-control" id="confirmPassword">
    </div>
    <div class="modal-footer text-center">
      <button *ngIf="!isLoading" type="submit" class="btn btn-primary text-center">Guardar Cambios</button>
    </div>
  </form>
  <div *ngIf="isLoading" class="text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Cargando...</span>
    </div>
  </div>
</div>
