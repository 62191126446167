<div class="container py-4 formulario-container">
    <div class="card mb-3">
    <div class="alerta-navegador" id="alertaNavegador">
        Error: Si no puede registrarse y está utilizando Safari 
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Safari_browser_logo.svg/1200px-Safari_browser_logo.svg.png" alt="Safari" style="width: 20px;">
        , debería cambiar a otro navegador web. Ejemplo: Chrome:
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Google_Chrome_icon_%282011%29.png/48px-Google_Chrome_icon_%282011%29.png" alt="Chrome"> o Firefox Mozilla   
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Firefox_logo%2C_2019.svg/48px-Firefox_logo%2C_2019.svg.png" alt="Firefox">
    </div>
    <div class="mb-3 text-center">
        <button  type="submit" class="btn btn-primary" (click)="cerrarAlerta()">Aceptar</button>
      </div>
    </div>
</div>    
    
    <!-- Aquí va el resto de tu HTML -->
    
 