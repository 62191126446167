<footer class="bg-dark text-white mt-4">
    <div class="container py-4">
      <div class="row">
        <div class="col-md-3">
          <h5>Sobre Nosotros</h5>
          <p>
            Somos una Asociación dedicada a promover y defender la actuación de Arbitros y Jueces Deportivos a través de diversas actividades y programas.
          </p>
        </div>
        <div class="col-md-3">
          <h5>Enlaces Rápidos</h5>
          <ul class="list-unstyled">
            <li><a class="text-white" routerLink="/home">Inicio</a></li>
            <li><a class="text-white" routerLink="/registro">Afiliación</a></li>
            <li><a class="text-white" routerLink="/noticias-anjade">Noticias</a></li>
          </ul>
        </div>
        <div class="col-md-3" id="contact-section">
          <h5>Contacto</h5>
          <ul class="list-unstyled ">
            <li><i class="fas fa-map-marker-alt"></i> Calle Hurtado de Amezaga , 27, piso 6º Dpto. 6-1, 48008 Bilbao,Vizcaya</li>
            <li><i class="fas fa-phone"></i> +34 646 60 71 40</li>
            <li><i class="fas fa-envelope"></i> anjade&#64;anjade.es</li>
            <li><i class="fas fa-envelope"></i> delegaciones&#64;anjade.es</li>
          </ul>
        </div>
        <div class="col-md-3">
          <h5>Síguenos en</h5>
          <a href="https://www.facebook.com/groups/436998112141913" target="_blank" class="text-white me-3"><i class="fab fa-facebook-f"></i></a>
          <a href="https://twitter.com" target="_blank" class="text-white me-3"><i class="fab fa-twitter"></i></a>
          <a href="https://instagram.com" target="_blank" class="text-white me-3"><i class="fab fa-instagram"></i></a>
        </div>
      </div>
      <div class="text-center py-3">
        &copy; 2024 Anjade. Todos los derechos reservados.
      </div>
    </div>
  </footer>
  